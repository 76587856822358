export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDPP5iNjLyuePHphfrfdXF3NDvlhcRgE_s',
    authDomain: 'fannector-admin.firebaseapp.com',
    projectId: 'fannector-e0c20',
    storageBucket: 'fannector-e0c20.appspot.com',
    messagingSenderId: '681161849491',
    appId: '1:681161849491:web:5b6e5ba04698c42fffab66',
    measurementId: 'G-9W2CSPMLRM'
  },
  apiUrl: 'test',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
